<template>
   <div class="panel-container">
        <div class="left-panel"></div>
        <div class="right-panel">
            <div style="max-width: 500px;"  class="container-sm fade-in-up">
                <div class="text-center">
                    <img width="70" src="https://assets.revio.pro/images/rv-brand/revio-symbol.svg" alt="logo">
                    <img class="mb-5" width="250" src="https://assets.revio.pro/images/rv-brand/rv-logo.svg" alt="logo" hidden>
                    <div class="my-5">
                        <h1>We're Under Development</h1>
                        <p>We're working hard to bring you an amazing experience. Stay tuned for updates!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  width: 100%;
  height: 100%;
}

.panel-container {
            display: grid;
            grid-template-columns: 40% auto;
            grid-template-rows: auto;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        .left-panel {
            background: rgb(70, 33, 96);
            background: -moz-linear-gradient(140deg, rgba(70, 33, 96, 1) 0%, rgba(120, 60, 160, 1) 100%);
            background: -webkit-linear-gradient(140deg, rgba(70, 33, 96, 1) 0%, rgba(120, 60, 160, 1) 100%);
            background: linear-gradient(140deg, rgba(70, 33, 96, 1) 0%, rgba(120, 60, 160, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#462160", endColorstr="#783ca0", GradientType=1);
        }

        .left-panel::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-image: url("https://assets.revio.pro/images/rv-brand/revio-symbol.svg");
            background-size: 150%;
            background-position: 30%;
            background-color: var(--primary-color-dark);
            background-repeat: no-repeat;
            opacity: 0;
            animation: fadeInRight 1.2s ease forwards; /* Smoother animation with longer duration */
        }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      background-position: 75%;
    }
    to {
      opacity: 1;
      background-position: 50%;
    }
  }

        .right-panel {
            display: flex;
        }

        @media screen and (max-width: 900px) {
            .panel-container {
                display: block;
                height: 100%;
            }

            .left-panel {
                display: none;
            }

            .right-panel {
                flex-flow: row;
                -webkit-box-pack: start;
                place-content: stretch flex-start;
            }
        }
</style>
